const routes = {
  items: [
    {
      path: '/',
      label: 'Главная',
      name: 'home',
      icon: 'mdi-folder-home',
      component: () => import('./views/Home.vue'),
    },
    {
      path: '/station',
      label: 'Заправочные станции',
      name: 'station',
      icon: 'mdi-gas-station',
      component: () => import('./views/Station.vue'),
    },
    {
      path: '/fueling',
      label: 'Заправки',
      name: 'fueling',
      icon: 'mdi-credit-card-marker-outline',
      component: () => import('./views/Fueling.vue'),
    },
  ],
  groups: [
    {
      label: 'Статистика',
      icon: 'mdi-google-analytics',
      items: [
        {
          path: '/stat/summary',
          label: 'Суммарно',
          name: 'stat',
          icon: 'mdi-google-analytics',
          component: () => import('./views/stat/Stat.vue'),
        },
        {
          path: '/stat/station',
          label: 'Статистика по заправкам',
          name: 'stat-station',
          icon: 'mdi-google-analytics',
          component: () => import('./views/stat/StatStation.vue'),
        },
        {
          path: '/stat/mileage',
          label: 'Пробег',
          name: 'mileage',
          icon: 'mdi-google-analytics',
          component: () => import('./views/stat/Mileage.vue'),
        },
        {
          path: '/stat/cost/per-liter',
          label: 'Цена за литр',
          name: 'cost-per-liter',
          icon: 'mdi-google-analytics',
          component: () => import('./views/stat/cost/PerLiter.vue'),
        },
      ],
    },
  ],
}

const getRoutes = () => {
  let routesList = []

  routes.items.forEach((item) => routesList.push({
    path: item.path,
    name: item.name,
    component: item.component,
    meta: {
      guest: false,
    },
  }))

  routes.groups.forEach((group) => group.items.forEach((item) => routesList.push({
    path: item.path,
    name: item.name,
    component: item.component,
    meta: {
      guest: false,
    },
  })))

  routesList.push({
    path: '/login',
    name: 'Авторизация',
    component: () => import('./views/Login.vue'),
    meta: {
      guest: true,
    },
  })

  return routesList
}

const getMenu = () => {
  return routes
}

export default {
  getRoutes,
  getMenu,
}
