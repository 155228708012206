<template>
    <v-list
        nav
        dense
    >
      <v-list-item v-for="(item, i) in this.routes.items" :key="i" :to="item.path" link>
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-title v-text="item.label"></v-list-item-title>
      </v-list-item>

      <v-list-group
          v-for="item in this.routes.groups"
          :key="item.label"
          :prepend-icon="item.icon"
          no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.label"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
            v-for="child in item.items"
            :key="child.title"
            :to="child.path"
            link
        >
          <v-list-item-title v-text="child.label"></v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    'routes': Object
  },
  data: () => ({
  }),
};
</script>