<template>
  <v-list>
    <v-list-item>
      <v-list-item-avatar>
        <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
      </v-list-item-avatar>
    </v-list-item>

    <v-list-item link>
      <v-list-item-content>
        <v-list-item-title class="title">User User</v-list-item-title>
        <v-list-item-subtitle>user@gmail.com</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'UserInfo',
};
</script>