
const getToken   = ()      => localStorage.getItem('jwt') ?? null
const setToken   = (token) => localStorage.setItem('jwt', token)
const resetToken = ()      => localStorage.removeItem('jwt')
const hasToken   = ()      => getToken() !== null

export default {
    getToken,
    setToken,
    resetToken,
    hasToken,
}
