<template>
  <v-app>
    <v-navigation-drawer app permanent>
      <UserInfo />

      <v-divider></v-divider>

      <Menu v-bind:routes="routes"/>
    </v-navigation-drawer>

    <v-app-bar app>
      <!-- -->
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <notifications position="top right" />
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
import Menu from './components/Menu.vue'
import UserInfo from './components/UserInfo.vue'
import routes from './routes'

export default {
  name: 'App',
  computed: {
    routes() {
      return routes.getMenu()
    }
  },
  components: {
    Menu,
    UserInfo,
  },
}
</script>