import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '../routes'
import storage from "./storage"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes.getRoutes(),
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.guest !== true)) {
    if (!storage.hasToken()) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })

      return
    }
  }

  next()
})

export default router
